import React from 'react'

import {
    Container,
    Stack,
    Row
} from 'react-bootstrap';

import Table from 'react-bootstrap/Table';

function PathPlannerAPI() {

    let jsonFeature = '{' +
        '"type": "FeatureCollection",'+
        '"features": ['+
            '{'+
                '"type": "Feature",'+
                '"properties": {},'+
                '"geometry": {'+
                    '"coordinates": ['+
                        '['+
                            '13.416009574225257,'+
                            '52.50181190820879'+
                        '],'+
                        '['+
                            '13.388615104304222,'+
                            '52.508787485290895'+
                        ']'+
                    '],'+
                    '"type": "LineString"'+
                '}'+
            '},'+
            '{'+
                '"type": "Feature",'+
                '"properties": {},'+
                '"geometry": {'+
                    '"coordinates": ['+
                        '['+
                            '['+
                                '13.397932498507998,'+
                                '52.504573463079225'+
                            '],'+
                            '['+
                                '13.405631680134519,'+
                                '52.504573463079225'+
                            '],'+
                            '['+
                                '13.405631680134519,'+
                                '52.50955256311178'+
                            '],'+
                            '['+
                                '13.397932498507998,'+
                                '52.50955256311178'+
                            '],'+
                            '['+
                                '13.397932498507998,'+
                                '52.504573463079225'+
                            ']'+
                        ']'+
                    '],'+
                    '"type": "Polygon"'+
                '}'+
            '}'+
        ']'+
    '}';
        
    let code = JSON.stringify(JSON.parse(jsonFeature), null, 2);
    return (
        <Container className='px-1'>

            <Stack gap={3}>
                <Row className='mb-3'>

                    <div className='text-justify'>

                        <h4 className='mb-4'>Path-Planner public API.</h4>
                        <h5 className='mt-5 mb-4'>API Key:</h5>
                        <p>
                            Before diving into API integration, make sure that you have an <b>[API_KEY]</b>.
                            To get a key you have to create an account, key can be generated in user dashboard.
                        </p>
                        <p>
                            Why is it necessary? We would prefer to reduce unusual usage of cloud tool.
                        </p>
                        <p><b><u>FOR TEST PURPOSE API KEY VALIDATION IS DISABLED.</u></b></p>


                        <h5 className='mt-5 mb-4'>API Endpoint:</h5>
                        <p>
                            Path-planning tool service is available usin only one endpoint, using POST request. Your client
                            application have to provide request body in GeoJSON format, and parameters(listed below):
                        </p>
                        <p>
                            <u>Endpoint</u>: <b>http://skymate.cloud/planner/generate</b>
                        </p>
                        <p>
                            <u>Request body</u>: <b>GeoJSON</b>. GeoJSON must contain at least one <b>LineStrip</b> with at
                            least <b>2 points</b> feature to proceed. Theese two points are <b>start</b> and <b>goal</b> locations.
                            Polygon features means no-fly zones and will be avoided in generation process.
                        </p>
                        <p>
                            <u>Header token</u>: <b>API_KEY</b>. Header token must be provided to validate request.
                        </p>
                        <p>
                            <u>Parameters</u>:<b>http://skymate.cloud/planner/generate?padding=100&format=0&...</b>
                        </p>
                        <p><i>All of theese parameters are optional:</i></p>
                        <Table striped>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Type</th>
                                    <th>Default</th>
                                    <th>Description</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>padding</td>
                                    <td>integer</td>
                                    <td>0</td>
                                    <td>
                                        Obstacles padding. <br />
                                        Path generator will try to hold minimal distance from obstacle polygons.<br />
                                        Value in meters.
                                    </td>
                                </tr>
                                <tr>
                                    <td>format</td>
                                    <td>integer</td>
                                    <td>0</td>
                                    <td>
                                        Type of generated output. <br />
                                        Path generator by default gives GeoJSON as a result output. But also supports diferent kind of formats: <br />
                                        0 - GeoJSON;<br />
                                        1 - QGroundControl plan;<br />
                                        2 - KML;
                                    </td>
                                </tr>
                                <tr>
                                    <td>agl</td>
                                    <td>float</td>
                                    <td>50.0</td>
                                    <td>Minimal altitude above ground given in meters.</td>
                                </tr>

                                <tr>
                                    <td>step_size</td>
                                    <td>float</td>
                                    <td>100.0</td>
                                    <td>Default step size in a point cloud that is used for path finding research.</td>
                                </tr>
                                <tr>
                                    <td>max_iterations</td>
                                    <td>integer</td>
                                    <td>1000</td>
                                    <td>
                                        Default ammount of iterations used to find a way. <br/>
                                        Don't set it too high to reach better results. Algorithm is already optimized to solve bottlenecks.
                                    </td>
                                </tr>
                                <tr>
                                    <td>srtm</td>
                                    <td>integer</td>
                                    <td>1</td>
                                    <td>
                                        Use SRTM elevation profile for planned path adjustment. <br/>
                                        If set to 0, no altitude will be provided. May reduce time of path generator, 
                                        but in this case you have to adjust altitude on client side.
                                    </td>
                                </tr>
                                <tr>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>aircraft</td>
                                    <td>integer</td>
                                    <td>0</td>
                                    <td>Type of aircraft (not implemented yet.)</td>
                                </tr>
                            </tbody>
                        </Table>

                        <p>
                            Use Postman to send test requests.
                        </p>
                        <h5 className='mt-5 mb-4'>Request example:</h5>
                        <p>
                            Let's assume we would like to reach a location as soon as possible, 
                            but it's not allowed to fly through polygon geofence. <br/>
                            We have integrated skymate public API into ground station or even 
                            on companion computer on board with LTE modem.
                        </p>
                        <p>
                            POST request to an endpoint: <br/>
                            <b>http://skymate.cloud/planner/generate?padding=100</b>
                        </p>
                        <p>
                            Request body (application/json):<br/>
                            <pre className='code'>{code}</pre>
                        </p>
                        <p className='mb-5'>
                            Output will generate a path in GeoJSON format.
                        </p>
                    </div>
                </Row>
            </Stack>
        </Container >
    )
}

export default PathPlannerAPI;