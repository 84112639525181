import React from 'react'

import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Stack } from 'react-bootstrap';
import { Form, Button, Image } from 'react-bootstrap';
// import { Alert } from 'react-bootstrap';


import { registerUser } from '../../store/actions/userActions'


function RegistrationForm(props) {
    
    const [inputs, setInputs] = useState({});

	const handleSubmit = (event) => {
		event.preventDefault();
		
		// Stupid object wraping
		props.props.registerUser(inputs.name, inputs.email, inputs.password)
	}

	const handleChange = (event) => {
		const name = event.target.name;
		const value = event.target.value;
		setInputs(values => ({ ...values, [name]: value }))
	}

    return(
        <Form onSubmit={handleSubmit}>
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Your name:</Form.Label>
				<Form.Control
					type="text"
					name="name"
					placeholder="Enter your name"
					value={inputs.name || ""}
					onChange={handleChange}
				/>
			</Form.Group>
			
			<Form.Group className="mb-3" controlId="formBasicEmail">
				<Form.Label>Email address:</Form.Label>
				<Form.Control
					type="email"
					name="email"
					placeholder="Enter email"
					value={inputs.email || ""}
					onChange={handleChange}
				/>
				<Form.Text className="text-muted">
					We'll never share your email with anyone else.
				</Form.Text>
			</Form.Group>

			<Form.Group className="mb-3" controlId="formBasicPassword">
				<Form.Label>Password:</Form.Label>
				<Form.Control
					type="password"
					name="password"
					placeholder="Password"
					value={inputs.password || ""}
					onChange={handleChange}
				/>
			</Form.Group>

			<Button variant="primary" type="submit">
				Sign in
			</Button>
		</Form>
    )
}

function Register(props) {
    return (
        <Container className='py-5'>
            <Stack gap={3}>
                <Row className='justify-content-md-center'>


                    <Col md={6} className='login-form-container py-3 px-5'>
                        <div className='login-form-contents'>
                            <Stack gap={3}>
								
								<Link to="/">
                  					<Image className="logo-login-register my-3" src="/LogoGray.png" rounded />
                				</Link>

                                <h1>Sign up</h1>
                                <p>Welcome to drone family</p>

                                <RegistrationForm props={props} />
                                
                                <div>Already have an account? <Link to="/profile/login">Login here.</Link></div>
                            </Stack>
                        </div>
                    </Col>
                </Row>
            </Stack>
        </Container>
    )
}



export default connect(null, { registerUser })(Register);