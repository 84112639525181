import React from 'react'

import { Link } from 'react-router-dom';


function HeroBanner() {
  return (
    <div className='hero-container py-4 particle-network-animation'>
      <div className="text-center hero-headline">
        <h1 className="display-5 fw-bold hero-header">Drone SaaS Platform</h1>
        <div className="mx-auto">
          <p className="lead mb-4 hero-sub-header">
            Live feed and automated flight path planing service<br />
            Fleet management system for critical first responders
          </p>
          <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">

            <Link to="/profile/registration"><button type="button" className="btn btn-light btn-lg px-4 gap-3">Get started</button></Link>
            <Link to="/documentation/path-planner"><button type="button" className="btn btn-outline-light btn-lg px-4">Documentation</button></Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default HeroBanner;