import { configureStore } from '@reduxjs/toolkit'
import { userReducer } from './reducers/userReducer'

// Automatically adds the thunk middleware and the Redux DevTools extension
const store = configureStore({
    // Automatically calls `combineReducers`
    reducer: {
        user: userReducer,
    }
})

export default store;