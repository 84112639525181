import React from 'react'

import { useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Container, Row, Col, Stack } from 'react-bootstrap';
import { Form, Button, Image } from 'react-bootstrap';
import { Alert } from 'react-bootstrap';

import { loginUser } from '../../store/actions/userActions'
import { USER_STATUS } from '../../store/types'



function LoginForm(props) {

  const [inputs, setInputs] = useState({});

  
  const handleSubmit = (event) => {
    event.preventDefault();
    props.props.loginUser(inputs.email, inputs.password)
  }


  const handleInputChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({ ...values, [name]: value }))
  }


  return (
    <Form className="my-3" onSubmit={handleSubmit}>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label>Email address:</Form.Label>
        <Form.Control
          type="email"
          name="email"
          placeholder="Enter email"
          value={inputs.email || ""}
          onChange={handleInputChange}
        />
        <Form.Text className="text-muted">
          We'll never share your email with anyone else.
        </Form.Text>
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
        <Form.Label>Password:</Form.Label>
        <Form.Control
          type="password"
          name="password"
          placeholder="Password"
          value={inputs.password || ""}
          onChange={handleInputChange}
        />
      </Form.Group>

      <Button variant="primary" type="submit">
        Sign in
      </Button>
    </Form>
  )
}


function ErrorBlock(errorStatus) {
  console.log(errorStatus.errorStatus)
  
  if (errorStatus.errorStatus === USER_STATUS.WRONG_CREDENTIALS) {
    return (
      <Alert variant="danger">
        <Alert.Heading>Wrong credentials.</Alert.Heading>
        <p>
          Please retry
        </p>
      </Alert>
    )
  } else {
    return (
      <></>
    )
  }
}


function Login(props) {
  return (

    <Container className='py-5'>
      <Stack gap={3}>

        
        <Row className='justify-content-md-center'>
          <Col md={6} className='login-form-container py-3 px-5'>
            <div className='login-form-contents'>
              <Stack gap={3}>
          
                <Link to="/">
                  <Image className="logo-login-register my-3" src="/LogoGray.png" rounded />
                </Link>

                <h1>Sign in</h1>
                <p>Welcome back</p>

                <LoginForm props={props} />
                
                <ErrorBlock errorStatus={props.user.loginStatus} />

                <div className='form-text-centered'>Don't have an account? <Link to="/profile/registration">Register here.</Link></div>
              </Stack>
            </div>
          </Col>
        </Row>

      </Stack>
    </Container>

  )
}

const mapStateToProps = (state) => ({ user: state.user })

export default connect(mapStateToProps, { loginUser })(Login)
