import {GET_USER, USER_ERROR, USER_STATUS, UPDATE_USER} from '../types'

const userInitialState = {
    userName: "",
    userEmail: "",
    userCreatedAt: "",
    loginStatus: USER_STATUS.NOT_LOGGED_IN,
};

export const userReducer = (state = { ...userInitialState }, action) => {
    switch (action.type) {

        case UPDATE_USER:
		case GET_USER:
            return { 
                ...state, 
                userName: action.payload.userName,
                userEmail: action.payload.userEmail,
                userCreatedAt: action.payload.userCreatedAt,

                loginStatus: action.payload.loginStatus, 
            };

        case USER_ERROR:
            return { 
                ...state, 
                userName: "",
                userEmail: "",
                userCreatedAt: "",

                loginStatus: action.payload.loginStatus, 
            };

		default:
			return state;
	}
    
};