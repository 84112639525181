let isDocker = process.env.NODE_ENV === "production";

const protocol = isDocker ? "http://" : "http://"

const host = isDocker ? "skymate.cloud" : "localhost"
const port = isDocker ? "/user/" : ":8010/api/v1/";

export const USER_INFO_EP       =  protocol + host + port + 'getuser'
export const USER_LOGIN_EP      =  protocol + host + port + 'login'
export const USER_REGISTER_EP   =  protocol + host + port + 'register'
export const USER_UPDATE_EP     =  protocol + host + port + 'update'




