import React from 'react'

function ErrorPage() {

    return (
        <div className='centered-div-container'>
            <b>404</b> Page not found.
        </div>
    )
}

export default ErrorPage