import { Outlet } from "react-router-dom";

import TopMenu from "../components/TopMenu";
import Footer from '../components/Footer';

function OutletSubApp() {
    return (
        // Application page composition is placed here
        <>
            <TopMenu />
            <Outlet/>   
            <Footer />         
        </>
    )
}

export default OutletSubApp;