import React from 'react'

import {
    Container,
    Row,
    Col
} from 'react-bootstrap';


const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Row>
                    <Col sm={10}>
                        skymate.cloud
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}

export default Footer;