import { GET_USER, USER_ERROR, USER_STATUS, UPDATE_USER } from '../types'

import { 
    USER_INFO_EP, 
    USER_LOGIN_EP, 
    USER_REGISTER_EP,
    USER_UPDATE_EP,
} from '../../common/endpoints'

import axios from 'axios'



export const getUser = () => async dispatch => {

    let authToken = localStorage.getItem("auth_token")

    if(authToken == null){
        dispatch({
            type: USER_ERROR,
            payload: {
                loginStatus: USER_STATUS.NOT_LOGGED_IN
            }
        })
        return
    }

    const headers = {
        'Token': authToken,
        'Content-Type': 'text/json',
    }
    await axios
        .get(USER_INFO_EP, { headers })
        .then((response) => {
            
            console.log(response.data)

            dispatch({
                type: GET_USER,
                payload: {
                    userName: response.data.userName,
                    userEmail: response.data.email,
                    userCreatedAt: response.data.createdAt,
                    loginStatus: USER_STATUS.LOGGED_IN
                }
            })
        })
        .catch((error) => {
            if (error.response) {
                // console.log(error.response.data);
                // console.log(error.response.status);
                // console.log(error.response.headers);
            }

            dispatch({
                type: USER_ERROR,
                payload: {
                    loginStatus:USER_STATUS.SERVICE_UNAVAILABLE
                }
            })
        })
}

export const loginUser = (userEmail, userPassword) => async dispatch => {
    
    const json = JSON.stringify({ 
        email: userEmail,
        password: userPassword
    });

    console.log(json);

    await axios
        .post(USER_LOGIN_EP, json, 
            { 
                headers: { 'Content-Type': 'application/json' },
                withCredentials: false,
            })
        .then((response) => {
            
            console.log(response.status)
            
            if(response.status === 200) {
                let authToken = response.data.authToken
                localStorage.setItem("auth_token", authToken)
            }
            // not sure if it's okay
            document.location.href="/";
        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)

                if(error.response.status === 403 || error.response.status === 400){
                    dispatch({
                        type: USER_ERROR,
                        payload: {
                            loginStatus: USER_STATUS.WRONG_CREDENTIALS
                        }
                    })
                }
            }

        })
}

export const registerUser = (userName, userEmail, userPassword) => async dispatch => {

    const json = JSON.stringify({ 
        email: userEmail,
        password: userPassword,
        userName: userName
    });

    await axios
        .post(USER_REGISTER_EP, json, { headers: { 'Content-Type': 'text/json' }})
        .then((response)=>{
            
            if(response.status === 200) {
                let authToken = response.data.authToken
                localStorage.setItem("auth_token", authToken)
            }

            document.location.href="/";

        }).catch((error)=>{
            if (error.response) {
                console.log(error.response.data)
                console.log(error.response.status)
                console.log(error.response.headers)

                if(error.response.status === 405 || error.response.status === 400) {
                    console.log("User exists!")
                    console.log(error.response)
                    dispatch({
                        type: USER_ERROR,
                        payload:{
                            loginStatus: USER_STATUS.USER_EXISTS
                        }
                    })
                }
            }
        })
}

export const updateUser = (state) => async dispatch => {

    const json = JSON.stringify({ 
        name: state.userName,
        userRole: state.userRole,
        
        country: state.userCountry,
        region: state.userRegion,
        city: state.userCity,
        postalCode: state.userPostalCode,
        street: state.userStreet,

        email: state.userEmail,
        statusText: state.userStatusText,

        profileImageUrl: state.profileImageUrl,
        profileImageId: state.profileImageId,
    })

    let authToken = localStorage.getItem("auth_token")

    console.log("Token: ", authToken)
    console.log("Data: ", json)

    if(authToken == null){
        dispatch({
            type: USER_ERROR,
            payload: {
                loginStatus: USER_STATUS.NOT_LOGGED_IN
            }
        })
        return
    }

    const headers = {
        'Token': authToken,
        'Content-Type': 'text/json',
    }

    await axios
        .patch(USER_UPDATE_EP, json, {headers})
        .then((response) => {
            
            dispatch({
                type: UPDATE_USER,
                payload: {
                    userName: response.data.name,
                    userRole: response.data.userRole,
                    
                    userCountry: response.data.country,
                    userRegion: response.data.region,
                    userCity: response.data.city,
                    userPostalCode: response.data.postalCode,
                    userStreet: response.data.street,
                    
                    userEmail: response.data.email,
                    userStatusText: response.data.statusText,
                    
                    profileImageUrl: response.data.profileImageUrl,
                    profileImageId: response.data.profileImageId,

                    userCreatedAt: response.data.createdAt,

                    loginStatus: USER_STATUS.LOGGED_IN
                }
            })

        })
        .catch((error) => {
            if (error.response) {
                console.log(error.response.data);
                console.log(error.response.status);
                console.log(error.response.headers);
            }

            dispatch({
                type: USER_ERROR,
                payload: {
                    loginStatus:USER_STATUS.SERVICE_UNAVAILABLE
                }
            })
        })
}
