import React from 'react'

import {
    Container,
    Image,
    Stack,
    Row
} from 'react-bootstrap';

function PathPlannerPage() {
    return (
        <Container className='px-1'>
            <Stack gap={3}>
                <Row className='mb-3'>
                    <div className='image-container'>
                        <Image src="/images/path-planner-banner.png" rounded />
                    </div>
                </Row>
            </Stack>

            <Stack gap={3}>
                <Row className='mb-3'>

                    <div className='text-justify'>
                        <h4 className='mt-3 mb-4'>Path-planning tool.</h4>
                        <p>Empower your unmanned aircraft operations with cutting-edge technology from skymate.
                            Our innovative cloud-based service brings a groundbreaking solution to the aviation
                            industry by automating flight path generation for copters and airplanes.
                        </p>

                        <h5 className='mt-5 mb-4'>Automated Flight Path Generation:</h5>
                        <p>Utilizing state-of-the-art algorithms, our system generates optimal flight paths effortlessly, enhancing efficiency and safety during operations.</p>

                        <h5 className='mt-5 mb-4'>Polygon-based Avoidance Areas:</h5>
                        <p>By intelligently utilizing polygons as avoidance areas, our system ensures obstacle-free navigation, reducing risks and ensuring smoother flights.</p>

                        <h5 className='mt-5 mb-4'>Real-time Adaptability:</h5>
                        <p>Our service offers real-time updates and adaptability to changing environments, guaranteeing flexibility and responsiveness during missions.</p>

                        <h4 className='mt-5 mb-4'>How it Works</h4>
                        <h5 className='mt-5 mb-4'>Effortless Integration:</h5>
                        <p>Seamlessly integrate our cloud service into your existing unmanned aircraft systems, simplifying the process and enhancing functionality.</p>

                        <h5 className='mt-5 mb-4'>User-Friendly Interface:</h5>
                        <p>Our intuitive interface makes it easy to customize flight paths, set parameters, and manage missions efficiently.</p>

                        <h5 className='mt-5 mb-4'>Enhanced Safety Measures:</h5>
                        <p>With advanced algorithms and avoidance strategies, ensure a higher level of safety for your aerial operations, meeting industry standards and regulations.</p>

                        <h4 className='mt-5 mb-4'>Experience the Future of Unmanned Aircraft Navigation.</h4>
                        <p>Join the revolution in aerial navigation with skymate. Elevate your operations, optimize efficiency, and ensure safer flights with our cloud-based service designed for the future of aviation.</p>

                        <p><i>Path planning tool is a service in development stage. Stay tuned.</i></p>
                    </div>
                </Row>
            </Stack>
        </Container >
    )
}

export default PathPlannerPage;