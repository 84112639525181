import React from 'react'

import {
    Container,
    Stack,
    Row,
    Col,
    Image
} from 'react-bootstrap';

function About() {
    return (
        <Container className='py-5'>
            <Stack gap={3}>
                <Row className='row-content'>
                    <Col sm={7}>
                        <div className='text-justify'>
                            <h3 className='mb-5 mt-2'>Hi, I am skymate:</h3>

                            <p>
                                Skymate.cloud is a drone collaboration and fleet management platform.
                                Unlock the Full Potential of Your Drone Fleet with Cutting-Edge SaaS Solutions.
                            </p>
                            <p>
                                Welcome to Skymate.cloud, where innovation meets efficiency in managing and optimizing
                                drone fleets. Our industry-leading SaaS platform offers a comprehensive toolkit designed
                                specifically for the dynamic needs of modern drone projects.
                            </p>
                            <p>
                                Empower your team with intuitive path planning algorithms and a robust suite of tools tailored
                                for seamless day-to-day drone operations. From streamlining workflows to enhancing precision in
                                flight planning, our solution is your ultimate ally in harnessing the true capabilities of drone
                                technology.
                            </p>
                            <p>If you are interested in supporting the project or want to report bugs, feel free to leave a message.</p>
                        </div>
                        <div className='text-justify'>
                            <h3 className='mb-2 mt-5'>Contact:</h3>
                            <p>contact[at]skymate.cloud</p>
                        </div>
                    </Col>
                    <Col sm={5}>
                        <h3 className='mb-5 mt-2'>Roadmap:</h3>
                        <div className='image-container-borderless mb-5'>
                            <Image src="/images/dev-roadmap.png" rounded />
                        </div>
                    </Col>

                </Row>

            </Stack>
        </Container>
    )
}

export default About;