import React from 'react'

import TopMenu from "../components/TopMenu";
import Footer from '../components/Footer';
import { Outlet, Link } from 'react-router-dom';

import {
    Container,
    Row,
    Col,
    Stack
} from 'react-bootstrap';

import Accordion from 'react-bootstrap/Accordion';

function OutletDocumentation() {
    return (
        <>
            <TopMenu />

            <Container className='py-5'>
                <Stack gap={3}>
                <Row>

                    <Col sm={3}>
                        <Accordion defaultActiveKey="0">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header><Link to="path-planner">Path planning tool</Link></Accordion.Header>
                                <Accordion.Body>
                                    <Stack><Link to="path-planner">Common information</Link></Stack>
                                    <Stack><Link to="path-planner-playground">API playground</Link></Stack>
                                    <Stack><Link to="path-planner-api">API documentation</Link></Stack>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="1">
                                <Accordion.Header>About</Accordion.Header>
                                <Accordion.Body>
                                    <Stack><Link to="contents">Contents</Link></Stack>
                                    <Stack><Link to="contents">Integration</Link></Stack>
                                    <Stack><Link to="contents">Functions</Link></Stack>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Col>

                    <Col sm={9}><Outlet /></Col>
                </Row>
                </Stack>
            </Container>

            <Footer />
        </>
    )
}

export default OutletDocumentation;