/* USER REDUCER */

export const GET_USER           = 'GET_USER'
export const USER_ERROR         = 'USERS_ERROR'
export const UPDATE_USER        = 'UPDATE_USER'

export const USER_STATUS = {
    NOT_LOGGED_IN: 0,
    LOGGED_IN: 1,

    WRONG_CREDENTIALS: 2,
    SERVICE_UNAVAILABLE: 3,
    USER_EXISTS: 4,
}
