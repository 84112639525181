import React from 'react'

import {
    Container,
    Stack,
    Row
} from 'react-bootstrap';

function PathPlannerPlayground() {
    return (
        <Container className='px-1'>

            <Stack gap={3}>
                <Row className='mb-3'>

                    <div className='text-justify'>
                        <h4 className='mb-4'>Introducing Our API Playground Sandbox.</h4>
                        <p>Before diving into API integration, experience the power of our path planning API firsthand with our user-friendly Playground Sandbox, available directly on our webpage.</p>

                        <h5 className='mt-5 mb-4'>Experiment and Verify:</h5>
                        <p>Our Sandbox offers a safe and interactive environment to experiment with our path planning capabilities. Input various parameters, manipulate geoJSON data, and simulate diverse scenarios to witness how our API generates optimized flight paths for unmanned aircraft.</p>

                        <h5 className='mt-5 mb-4'>Hands-On Testing:</h5>
                        <p>Easily test the functionality and responsiveness of our path planning service. Play around with different settings, such as altitude, speed, waypoints, and mission types, to gauge how the API tailors flight plans according to your specifications.</p>

                        <h5 className='mt-5 mb-4'>Seamless Transition to Integration:</h5>
                        <p>The Playground Sandbox serves as a valuable tool for users to validate and understand our API's capabilities before integration. Gain confidence in our service's performance and ensure a smoother transition to integrating our path planning API into your UAV platform.</p>

                        <h5 className='mt-5 mb-4'>Start Testing Today:</h5>
                        <p>Explore our Playground Sandbox now and familiarize yourself with the robust capabilities of our path planning API. Get a glimpse of the optimized flight paths it can generate for your unmanned aircraft missions.</p>

                    </div>
                </Row>
            </Stack>
        </Container >
    )
}

export default PathPlannerPlayground;