import React from 'react'

function Logout() {
    
    // clean from local storage token
    let auth_token = localStorage.getItem("auth_token")
    if (auth_token != null) {
        console.log("Token is not null!")
        localStorage.removeItem("auth_token")

        console.log("check again" + localStorage.getItem("auth_token"))
    }

    document.location.href="/";
    return (
    <>
    </>
    )
}

export default Logout;