import React from 'react'

import TopMenu from "../components/TopMenu";
import Footer from '../components/Footer';
import { Outlet } from 'react-router-dom';


function OutletLanding() {
    return (
        <>
            <TopMenu />
            <Outlet />
            <Footer />
        </>
    )
}

export default OutletLanding;