import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Container, Nav, Navbar, NavDropdown } from 'react-bootstrap';


import { connect } from 'react-redux';
import { USER_STATUS } from '../store/types';


// Toggle user menu links if already authorized.
function UserMenu(user) {

    var loggedIn = user.user.loginStatus === USER_STATUS.LOGGED_IN

    // console.log(loggedIn)
    // console.log(user.user)

    if (loggedIn) {
        return (
            <Nav className="justify-content-end top-login-links">
                <NavDropdown title={`Hello ${user.user.userName}`} id="basic-nav-dropdown">
                    {/* <NavDropdown.Item as="div"><Link to="/user/profile/profile-activities">My profile</Link></NavDropdown.Item>
                    <NavDropdown.Item as="div"><Link to="/user/profile/profile-activities">Messages</Link></NavDropdown.Item> */}
                    {/* <NavDropdown.Divider /> */}
                    <NavDropdown.Item as="div"><Link to="/profile/logout">Logout</Link></NavDropdown.Item>
                </NavDropdown>
            </Nav>
        )
    } else {
        return (
            <Nav className="justify-content-end top-login-links">
                <Nav.Link href="/profile/login">Sign In</Nav.Link>
            </Nav>
        )
    }
}

function TopMenu(props) {

    const location = useLocation();
    let className = (location.pathname === "/") ? "navbar-override" : "navbar-override-content";

    return (
        <Navbar collapseOnSelect expand="lg" variant="dark" className={className}>
            <Container>
                <Navbar.Brand href="/">
                    <img
                        alt=""
                        src="/LogoGray.png"
                        width="45"
                        height="45"
                        margin="0 0 5px 0"
                        className="d-inline-block align-top top-branding-logo"
                    />
                    {' '}skymate
                </Navbar.Brand>

                <Navbar.Toggle aria-controls='responsive-navbar-nav' />
                <Navbar.Collapse id='responsive-navbar-nav'>

                    <Nav className="m-auto">
                        <Nav.Link as="div" active="true">
                            <Link to="/">Home</Link>
                        </Nav.Link>

                        <NavDropdown title="Tools" id="basic-nav-dropdown">
                            <NavDropdown.Item>
                                <Link to="/app/pathPlannerApp">Flight path generator</Link>
                            </NavDropdown.Item>
                            {/* <NavDropdown.Item href="not-found">Sora-Tool</NavDropdown.Item> */}
                        </NavDropdown>

                        <Nav.Link as="div" active="true">
                            <Link to="/documentation/path-planner">Documentation</Link>
                        </Nav.Link>

                        <Nav.Link as="div" active="true">
                            <Link to="/about">About</Link>
                        </Nav.Link>
                    </Nav>

                    <UserMenu user={props.user} />

                </Navbar.Collapse>
            </Container>
        </Navbar>
    );
}

const mapStateToProps = (state) => ({
    user: state.user
})

export default connect(mapStateToProps)(TopMenu);