import React from 'react'
import { Link } from 'react-router-dom';

import {
    Container,
    Stack,
    Row,
    Col,
    Image,
    Button,
} from 'react-bootstrap';
import HeroBanner from '../../components/HeroBanner';

function Home() {
    return (
        <>
            <HeroBanner />
            <Container className='py-5 mb-5'>
                <Stack gap={3}>
                    <Row className='row-content py-3 mb-3'>
                        <center><h3>Automated flight path generator. Status: [Public Testing].</h3></center>
                    </Row>
                    <Row className='row-content'>
                        <Col sm={4}>
                            <div className='image-container mb-5'>
                                <Image src="/images/path-planner-tool-color.png" rounded />
                            </div>
                        </Col>
                        <Col sm={8}>
                            <div className='text-justify'>
                                <h5 className='mb-3'>Optimized Flight Paths for Critical Response:</h5>
                                <p>Drones serve as Critical First Responders (CFR) in emergencies. Our automated path planning cloud service empowers first responders, ensuring faster, safer access to critical positions.</p>

                                <p>In emergencies like return-home scenarios, our service charts the safest routes, bypassing obstacles and respecting no-fly zones, enhancing safety and efficiency.</p>

                                <p>Find integration guidance in our API documentation. Seamlessly integrate our service into your UAV platform for optimized capabilities.</p>

                                <Link to="/app/pathPlannerApp"><Button type="button" className="btn btn-dark" size="sm">Live demo</Button>{' '}</Link>
                                <Link to="/documentation/path-planner"><Button variant="secondary" size="sm">API documentation</Button></Link>
                            </div>
                        </Col>
                    </Row>
                    <Row className='row-content block-underlined'>
                        <Col sm={4} className='form-text-centered'>
                            <div className='image-container-w30'>
                                <Image src="/images/speedometer_icon_bw.png" roundedCircle />
                            </div>
                            <h5>Incredibly fast</h5>
                            <p>
                                <i>
                                    Path generation takes 2-6ms. But, of course, result may be delivered with more latency,
                                    depending on connection.
                                </i>
                            </p>
                        </Col>
                        <Col sm={4} className='form-text-centered'>
                            <div className='image-container-w30'>
                                <Image src="/images/qground_icon_bw.png" roundedCircle />
                            </div>
                            <h5>QGroundControl compatible</h5>
                            <p>
                                <i>
                                    Generated path output format compatible with QGroundControl Ground station.
                                    By default is GeoJSON.
                                </i>
                            </p>
                        </Col>
                        <Col sm={4} className='form-text-centered'>
                            <div className='image-container-w30'>
                                <Image src="/images/customization_icon_bw.png" roundedCircle />
                            </div>
                            <h5>Highly customizable</h5>
                            <p>
                                <i>
                                    Path generator may be customized to reach better results. Parameters are listed in documentation section.
                                </i>
                            </p>
                        </Col>
                    </Row>
                </Stack>
            </Container>
        </>
    )
}

export default Home;