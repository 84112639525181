import MicroFrontend from "./../microfrontends/MicroFrontend";


const {
	REACT_APP_SUBAPP1_PORT: pathplannerPort,
	REACT_APP_SUBAPP1_HOST: pathPlannerApp,
	REACT_APP_SUBAPP2_PORT: soratoolPort,
	REACT_APP_SUBAPP2_HOST: soratoolApp,
} = process.env;


const PathPlannerApp = ({ history }) => {
	var subappHost = window.location.protocol + "//" + window.location.hostname + ":" + pathplannerPort;
	if(process.env.NODE_ENV === "production") {
		subappHost = pathPlannerApp;
	}
	console.log(subappHost);
	return (
		<MicroFrontend history={history} host={subappHost} name="pathPlannerApp" />
	)
};

const SubApp2 = ({ history }) => {
	var subappHost = window.location.protocol + "//" + window.location.hostname + ":" + soratoolPort;
	if(process.env.NODE_ENV === "production") {
		subappHost = soratoolApp;
	}
	return (
		<MicroFrontend history={history} host={subappHost} name="subapp2" />
	)
}



export { PathPlannerApp, SubApp2 }