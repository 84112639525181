import React from 'react'

import {
    Container
} from 'react-bootstrap';

function DocMainPage() {
    return (
        <Container className='px-1'>
            Documentation comming soon...
        </Container>
    )
}

export default DocMainPage;