import React from 'react';
import ReactDOM from 'react-dom';

import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'

import Store from './store/store'
import App from './App';

// Styles.
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/main.css"
import "./styles/sidebar.css";

import * as serviceWorker from './ServiceWorker';

const Root = ()=>(
    <BrowserRouter>
		<Provider store={Store}>
	  		<App/>
		</Provider>
	</BrowserRouter>
)


ReactDOM.render(<Root store={Store}/>, document.getElementById('root'));

serviceWorker.unregister();
