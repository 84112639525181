// React magic.
import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';

// Redux.
import { connect } from 'react-redux';
import { getUser } from './store/actions/userActions';

// Microfrontends.
import { PathPlannerApp, SubApp2 } from './microfrontends/SubApplications';

// Pages.
import OutletLanding from './pages/OutletLanding'
import OutletSubApp from "./pages/OutletSubApp";
import OutletLogin from './pages/OutletLogin';
import ErrorPage from "./pages/ErrorPage";

import Login from './pages/User/Login'
import Logout from './pages/User/Logout'
import Registration from './pages/User/Register';

import Home from './pages/Content/Home'
import About from './pages/Content/About'

// Documentation
import OutletDocumentation from './pages/OutletDocumentation';
import DocMainPage from './pages/Documentation/DocMainPage';
import PathPlannerPage from './pages/Documentation/PathPlanner/PathPlannerPage';
import PathPlannerPlayground from './pages/Documentation/PathPlanner/PathPlannerPlayground';
import PathPlannerAPI from './pages/Documentation/PathPlanner/PathPlannerAPI';


const App = props => {

	const [isRequested, setIsRequested] = useState(null);
	
	useEffect(() => {
		if(!isRequested) {
			props.getUser(isRequested);
			setIsRequested(true);
		}
		
	}, [props, isRequested]);
	
	return (
		<Routes>

			{/* Langing pages */}
			<Route path="/" 				Component={OutletLanding}>
				<Route path="" 				Component={Home} />
				<Route path="about" 		Component={About} />
				<Route path="*" 			Component={ErrorPage} />
			</Route>

			
			{/* Profile related pages */}
			<Route path="/profile" 			Component={OutletLogin}>
				<Route path="login" 		Component={Login} />
				<Route path="logout" 		Component={Logout} />
				<Route path="registration" 	Component={Registration} />
				<Route path="*" 			Component={ErrorPage} />
			</Route>

			{/* Documentation pages */}
			<Route path="/documentation" 				Component={OutletDocumentation}>
				<Route path="contents" 					Component={DocMainPage} />
				<Route path="path-planner"				Component={PathPlannerPage} />
				<Route path="path-planner-playground"	Component={PathPlannerPlayground} />
				<Route path="path-planner-api"			Component={PathPlannerAPI} />
				<Route path="*" 						Component={ErrorPage} />
			</Route>

			
			{/* Microfrontend applications */}
			<Route path="/app" 					Component={OutletSubApp}>
				<Route path="pathPlannerApp" 	Component={() => <PathPlannerApp />} />
				<Route path="subapp2" 			Component={() => <SubApp2 />} />
				<Route path="*" 				Component={ErrorPage} />
			</Route>

		</Routes>
	);
};

const mapStateToProps = (state) => ({ user: state.user })
const mapDispatchToProps = { getUser }

export default connect(mapStateToProps, mapDispatchToProps)(App)
